<template>
  <div>
    <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
          <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div v-else>
        <table-tariff-performer-price :tariffPerformerPrices="tariffPerformerPrices" @timerAfto="timerAfto" @editForm="editForm" @deleteTariffPrice="deleteTariffPrice"/>
    </div>
        <modal-price-create :pr_types="pr_types" :id="id" @refresh="refresh" />
        <modal-edit-price :pr_types="pr_types" :id="id" @refresh="refresh" :idModal="idModal" />
        <modal-delete-tariff-price :id="id" :idModal="idModal" @refresh="refresh"/>
  </div>
</template>

<script>
import tableTariffPerformerPrice from '@/views/component/Table/tableTariffPerformerPrice.vue'
import ModalPriceCreate from '@/views/component/Modal/ModalTariffPrices/ModalPriceCreate.vue'
import ModalEditPrice from '@/views/component/Modal/ModalTariffPrices/ModalEditPrice.vue'
import ModalDeleteTariffPrice from '@/views/component/Modal/ModalTariffPrices/ModalDeleteTariffPrice.vue'

export default {
    components: {
        tableTariffPerformerPrice,
        ModalPriceCreate,
        ModalEditPrice,
        ModalDeleteTariffPrice,
    },
    data() {
        return {
            id: this.$route.query.filter,
            showPreloader: false,
            timer: null,
            tariffPerformerPrices: [],
            pr_types: [],
            idModal: null,
        }
    },
    mounted() {
        this.$store.commit('pageData/setdataCount', null)
        let id = this.id
        this.refresh(id)
        this.tariffPerformerPricesData()
    },
    methods: {
        refresh(id){
            this.showPreloader = true
            this.$http.get(`tariff-performers/base/${id}/prices`)
                .then(res => {
                    this.tariffPerformerPrices = res.data
                    this.$store.commit('pageData/setdataCount', this.tariffPerformerPrices.length)
                    this.showPreloader = false
                    this.$store.commit('REFRESH_DATA', false)
                })
        },
        tariffPerformerPricesData() {
            this.$http
                .get('tariff-performers/base/prices/data')
                .then(res => {
                    this.pr_types = res.data.pr_types
            })
        },
        refreshIs_Active() {
            this.$http
                .get(`tariff-performers/base/${this.id}/prices`)
                .then(res => {
                    this.tariffPerformerPrices = res.data
            })
        },
        timerAfto(is_active) {
            is_active ? (this.timer = setInterval(() => this.refreshIs_Active(), 30000)) : clearInterval(this.timer);
        },
        editForm(id) {
            this.idModal = id
            setTimeout(this.openModalEdit, 0)
        },
        openModalEdit() {
            this.$bvModal.show('editPrice')
        },
        deleteTariffPrice(id) {
            this.idModal = id
            this.$bvModal.show('deleteTariffPrice')
        }
    },
    computed: {
      fetchingNewData(){
              return this.$store.state.refresh.fetchingNewData
      }
    },
    watch: {
      fetchingNewData(val){
            if (val) {
                this.$http
                .get(`tariff-performers/base/${this.id}/prices`)
                .then(res =>{
                    this.tariffPerformerPrices = res.data
                    this.$store.commit('pageData/setdataCount', this.tariffPerformerPrices.length)
                    this.$store.commit('REFRESH_DATA', false)
                })
            }
        }
    }
}
</script>
