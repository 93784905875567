<template>
  <b-modal
    :id="$route.name + 'CreateModal'"
    title="Добавить"
    size="sm"
    hide-footer
    mo-enforce-focus
    :no-close-on-backdrop="true"
    centered
    @close="clearData"
    >
    <b-row>
        <b-col>
          <div>
            <label>Тип запроса водителя: <i style="color: red;">*</i></label>
            <b-form-select v-model="createTariffPrice.performer_request_type_id" :options="pr_types" value-field="id" text-field="name" size="sm"></b-form-select>
          </div>
          <div style="margin-top: 10px;">
        <b-row>
          <b-col>
            <label style="font-size: 12px;">Настройка списания/начисления бонусов по запросу водителя</label>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="border p-2">
            <label style="font-size: 14px;">(Из списка заказов) от ...</label>
            <b-row>
              <b-col class="mt-2 border">
                <label style="margin-top: 5px;">от цены</label>
                <div style="column-count: 2;">
                  <label>Цена от:
                    <b-form-input v-model="by_price.price" type="number"></b-form-input>
                  </label>
                  <label>Комиссию:
                    <b-form-input v-model="by_price.percent" type="number"></b-form-input>
                  </label>
                  <b-row>
                    <div class="border ml-1" style="margin-top: 18px; margin-right: 10px; height: 100px; width: 250px;">
                      <div class="border" style="background-color: yellow;" @click.prevent="getid(i), is_active = false" v-for="(by_price, i) in createTariffPrice.by_price" :key="i">
                        {{ `От ${by_price.price} : ${by_price.percent}` }}
                      </div> 
                    </div>
                  </b-row>
                </div>
                <b-col class="d-flex justify-content-end my-1">
                  <b-button variant="primary" style="width: 100px; height: 40px; margin-right: 10px;" :disabled="!by_price.price > 0 || !by_price.percent > 0" @click.prevent="getPush">Добавить</b-button>
                  <b-button variant="secondary" style="width: 100px; height: 40xp;" :disabled="is_active" @click="deletePrice">Удалить</b-button>
                </b-col>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="mt-2 border">
                <label style="margin-top: 5px;">от расстояния</label>
                <div style="column-count: 2;">
                  <label>Расстояние от:
                  <b-form-input v-model="by_distance.distance" type="number"></b-form-input>
                  </label>
                  <label>Комиссию:
                    <b-form-input v-model="by_distance.percent" type="number"></b-form-input>
                  </label>
                  <b-row>
                    <div class="border ml-1" style="margin-top: 18px; margin-right: 10px; height: 100px; width: 250px;">
                      <div class="border" style="background-color: yellow;" @click.prevent="getid(i), is_activeDistance = false" v-for="(by_distance, i) in createTariffPrice.by_distance" :key="i">
                        {{ `От ${by_distance.distance} : ${by_distance.percent}` }}
                      </div>
                    </div>
                  </b-row>
                </div>
                <b-col class="d-flex justify-content-end my-1">
                  <b-button variant="primary" style="width: 100px; height: 40px; margin-right: 10px;" :disabled="!by_distance.distance > 0 || !by_distance.percent > 0" @click.prevent="getByDistance">Добавить</b-button>
                  <b-button variant="secondary" style="width: 100px; height: 40xp;" :disabled="is_activeDistance" @click="deleteByDistance" >Удалить</b-button>
                </b-col>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="mt-2 border">
                <label style="margin-top: 5px;">от длительности</label>
                <div style="column-count: 2;">
                  <label>Время (мин.) от:
                    <b-form-input v-model="by_time.time" type="number"></b-form-input>
                  </label>
                  <label>Комиссию:
                    <b-form-input v-model="by_time.percent" type="number"></b-form-input>
                  </label>
                  <b-row>
                    <div class="border ml-1" style="margin-top: 18px; margin-right: 10px; height: 100px; width: 250px;">
                      <div class="border" style="background-color: yellow;" @click.prevent="getid(i), is_activeTime = false" v-for="(by_time, i) in createTariffPrice.by_time" :key="i">
                        {{ `От ${by_time.time} : ${by_time.percent}` }}
                      </div>
                    </div>
                  </b-row>
                </div>
                <b-col class="d-flex justify-content-end my-1">
                  <b-button variant="primary" style="width: 100px; height: 40px; margin-right: 10px;" :disabled="!by_time.time > 0 || !by_time.percent > 0" @click.prevent="getByTime">Добавить</b-button>
                  <b-button variant="secondary" style="width: 100px; height: 40xp;" :disabled="is_activeTime" @click="deleteByTime">Удалить</b-button>
                </b-col>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
        </b-col>
    </b-row>
    <b-row>
        <b-col cols="12" class="d-flex justify-content-between">
            <b-button
            :disabled="submitButtonDisabled"
            class="mt-2 col-md-5"
            variant="primary"
            @click.prevent="createTariffPerformerPrices">
                Создать
            </b-button>
            <b-button class="mt-2 col-md-5" variant="secondary"
            @click.prevent="clearData">
                Отмена
            </b-button>
        </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import convertSendDataJson from '@/@core/utils/convertSendData'

export default {
  props: ['pr_types', 'id'],
  data() {
    return {
      is_active: true,
      is_activeDistance: true,
      is_activeTime: true,
      submitButtonDisabled: false,
      by_price: {
        price: null,
        percent: null,
      },
      by_distance: {
        distance: null,
        percent: null,
      },
      by_time: {
        time: null,
        percent: null,
      },
      idPrices: null,
      createTariffPrice: {
        btps_id: this.id,
        performer_request_type_id: null,
        by_price: [],
        by_distance: [],
        by_time: [],
      }
    }
  },
  methods: {
    createTariffPerformerPrices() {
      this.submitButtonDisabled = true
      let data = JSON.parse(JSON.stringify(this.createTariffPrice))
      convertSendDataJson(data.by_price)
      convertSendDataJson(data.by_distance)
      convertSendDataJson(data.by_time)
      this.$http
          .post(`tariff-performers/base/${this.id}/prices`, data)
          .then(res => {
            this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                      title: 'Успех!',
                      icon: 'CheckIcon',
                      variant: 'success',
                      text: res.data.message,
                  },
              })
              let id = this.id;
              this.$emit('refresh', id)
              this.clearData()
          })
          .catch(err => {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                title: 'Некорректные данные!',
                icon: 'XIcon',
                variant: 'danger',
                text: err.response.data.errors,
                },
          })
        })
        .finally(() => {
          this.submitButtonDisabled = false
        });
    },
    getid(i) {
      this.idPrices = i
    },
    getPush() {
      this.createTariffPrice.by_price.push(this.by_price)
      this.by_price = {
        price: null,
        percent: null,
      }
    },
    deletePrice() {
      this.createTariffPrice.by_price.splice(this.idPrices, 1)
      this.idPrices = null,
      this.is_active = true
    },
    getByDistance() {
      this.createTariffPrice.by_distance.push(this.by_distance)
      this.by_distance = {
        distance: null,
        percent: null,
      }
    },
    deleteByDistance() {
      this.createTariffPrice.by_distance.splice(this.idPrices, 1)
      this.idPrices = null,
      this.is_activeDistance = true
    },
    getByTime() {
      this.createTariffPrice.by_time.push(this.by_time)
      this.by_time = {
        time: null,
        percent: null,
      }
    },
    deleteByTime() {
      this.createTariffPrice.by_time.splice(this.idPrices, 1)
      this.idPrices = null,
      this.is_activeTime = null
    },
    clearData() {
      this.is_active = true,
      this.is_activeDistance = true,
      this.is_activeTime = true,
      this.submitButtonDisabled = false
      this.by_price = {
        price: null,
        percent: null,
      },
      this.by_distance = {
        distance: null,
        percent: null,
      },
      this.by_time = {
        time: null,
        percent: null,
      },
      this.idPrices = null,
      this.createTariffPrice = {
        btps_id: this.id,
        performer_request_type_id: null,
        by_price: [],
        by_distance: [],
        by_time: [],
      }
      this.$bvModal.hide(this.$route.name + 'CreateModal')
    }
  }

}
</script>

<style>

</style>